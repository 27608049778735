import manageData from "../shared/manage_data";

$(function(){

    app.DOM.filter = app.DOM.content.find('#filter');
    const $buttons = app.DOM.content.find('.buttons button');
    const formInitialState = app.DOM.form.serialize();
    const $trs = app.DOM.content.find('table tbody tr');
    const $licenceRegion = app.DOM.form_input.filter('.licence_region');

    function setupInputs()
    {
        const $inputs = app.DOM.form_input.not('[data-ajax], .original, .hidden, button');

        manageData.highlightEmptyInputs($inputs, $trs)

        // check empty
        $inputs.on('change', e => {
            if( app.DOM.form.serialize() === formInitialState ) {
                $buttons.hide();
                app.DOM.header_btn_save.hide();
            } else {
                $buttons.show();
                app.DOM.header_btn_save.show();
            }
        });

        $buttons.hide();
        app.DOM.header_btn_save.hide();

        // set min/max lengths for inputs based on region
        manageData.licenceNumberRegion($licenceRegion);
    }

    setupInputs();

    app.multiSelect();
});